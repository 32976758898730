// console.log('%c' + 'Steiermoooak <3', 'font-family:Comic Sans MS; font-size:50px; color:#fff; font-weight:bold; background: linear-gradient(#003610, #202020); border-radius: 5px; padding: 20px')

import {onFind} from "@elements/init-modules-in-scope";
import {matches} from "@elements/dom-utils";

import * as toc from '@elements/toc';
toc.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init();

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as renderTemplate from './renderTemplate';
renderTemplate.init();

import * as fadeIn from './fadeIn';
fadeIn.init();

import * as tableResponsive from './tableResponsive';
tableResponsive.init();

import * as customCursor from './custom-cursor';
customCursor.init();

import * as scrollingRow from './scrollingRow';
scrollingRow.init();

import * as formValidation from './formValidation';
formValidation.init();

import * as reloadForm from "@elements/reload-form";
reloadForm.init({
    validator:validator
});

import * as actionChanger from './elements-packages/action-changer';
actionChanger.init();

import Modal from 'bootstrap.native/dist/components/modal-native.esm';
onFind('[data-toggle="modal"]',function (element) {
    new Modal(element);
});

import * as floatingLabel from '@elements/floating-labels';
floatingLabel.init();

import * as recaptcha from './recaptcha';
recaptcha.init();


// import * as reloadForm from "@elements/reload-form";
// reloadForm.initInScope($('body'));

// import { showCookieBar } from "@elements/cookie-permissions";
// if (!_config.admin && !_config.editmode) {
//     showCookieBar();
// }

import * as tracking from "@elements/tracking";
tracking.initGtmEventTracking({});

import * as experienceSlider from './experience-slider';
experienceSlider.init();

function validator(form) {
    if(form.pristine && matches('.js-form-validation', form)){
        return form.pristine.validate();
    }else{
        return true;
    }
}

import * as infoOverlay from './info-overlay';
infoOverlay.init();

import * as infoModal from './info-modal';
infoModal.init();


import * as countdown from './countdown';
countdown.init();

import * as consent from './consent';
consent.init();

import * as navActiveState from './navigation-active-state';
navActiveState.init();